/* Style guide */

:root {
    /** Backgrounds **/
    --mainBackground: #183042;
    


    /** Text Colors **/
    --primaryText: #040E16;
    --secondaryText: #141522;
    --testText: red;
    
    
    --bodyBackground: black;
}