/* 
Harsh Sharma
App.css
*/

/* Base elements */
body {
  margin: 0;
  background-color: white;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

.pageElements {
  padding: 11vh 10vw;
  margin-bottom: 50px;
}


/* Base text */
.link{
  color: blue;
  text-decoration: underline;
}

/* Navigation bar ________________________________________*/
/* Base containers */
.websiteNavigation {
  background-color: #183042;
  height: 75px;
  width: 100%;
  padding-left: 10vw;
  padding-right: 10vw;
  display: inline-block;
  position: absolute;
}
.navbar {
  padding: 0;
  margin: 0;
}
.nav-link {
  padding: 8px 0 0 0;
  margin: 0;
}

.navSectionTitle {
  position: relative;
  width: 800px;
  height: 75px;
  padding: 1.3em 0;
}
.websiteTitle {
  font-family: Lato;
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
  color: white;
}

/* Page titles (expanded view) */
.navSectionElements {
  position: relative;
  width: 50%;
  height: 75px;
  padding: 1em 0;
}
.navbar-nav {
  float: right;
}
.pageTitle {
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
}
.linkContainer {
  display: inline;
  margin-left: 25px;
  vertical-align: top;
}
.linkSection {
  display: inline;
  margin-right: 10px;
}
.linkImage {
  height: 20px;
}


/* Menu button (narrow view) */
#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
  background-color: black;
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
  background-color: black;
}
#menu__toggle:checked ~ .menu__box {
  right: 0 !important;
}
.menu__btn {
  display: none;
  position: absolute;
  top: 2em;
  right: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: 140px;
  height: 100%;
  margin: 0;
  padding: 80px 20px;
  list-style: none;
  background-color: #ECEFF1;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
  transition-duration: .25s;
}
.menu__item {
  display: block;
  padding: 12px 24px;
  color: #333;
  font-family: Lato;
  font-size: 25px;
  font-weight: 500;
  text-decoration: none;
  transition-duration: .25s;
}
.menu__item:hover {
  background-color: #CFD8DC;
}

/* Define transition from page titles to menu */
@media screen and (max-width: 768px) {
  .navbar-nav {
    display: none;
  }
  .menu__btn {
    display: block;
  }
}

/* Page elements ________________________________________*/
.subtitle{
  font-family: 'League Spartan';
  font-size: 38px;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: black;
  padding-top: 40px;
  margin-bottom: 10px;
}

.sectionBody {
  margin-top: 15px;
}
.sectionParagraph {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

/* Case study links */
.subpagePreview {
  height: 100px;
}
.previewTitle {
  font-size: 25px;
  margin-bottom: 5px;
}
.previewBody {
  font-size: 15px;
}

/* Case study body */
.studyBody {
  width: 100%;
  font-size: 15px;
  padding-bottom: 100px;
  margin-top: 40px;
}
.modernSubTitle {
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 1px;
}
.studyTitle {
  font-size: 45px;
  font-family: 'League Spartan';
  font-weight: 700;
}
.productLink {
  color: blue;
  font-size: 12px;
  text-decoration: underline;
  width: 120px;
}
.detailsSection{
  width: 100%;
  margin-top: 30px;
}
.subDetailsSection{
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding-right: 10px;
}
.detailsTitle{
  font-size: 24px;
  font-family: 'League Spartan';
  font-weight: 600;
  margin-bottom: 7px;
}
.detailsParagraph{
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}
@media screen and (max-width: 768px) {
  .subDetailsSection {
    width: 100%;
  }
  .rightSubDetailsSection {
    padding-top: 30px;
  }
}
.techPill{
  display: inline-block;
  font-size: 13px;
  color: white;
  padding: 1px 12px;
  border-radius: 15px;
  margin-right: 15px;
  margin-bottom: 9px;
}
.swiftPill { background-color: #FF1F00;}
.back4appPill { background-color: #1E86E6}
.javascriptPill { background-color: #F7DF1E; color: black;}
.cssPill { background-color: #2A65F1;}
.htmlPill { background-color: #FF5800;}
.parsePill { background-color: #1598E7;}
.googlePill { background-color: #2CA952;}
.sketchPill { background-color: #FDAD00; color: black;}
.invisionPill { background-color: #DC395F;}
.trelloPill { background-color: #1A7EF8;}

.bulletList{
}
.bulletItem {
  margin-bottom: 7px;
}

.stepsParagraph{
  margin-top: 10px;
}

.secondaryTitle {
  font-size: 30px;
  font-family: 'League Spartan';
  font-weight: 700;
}

.diagramImage{
  max-width: 800px;
  width: 95%;
  margin: 20px 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.imageApolloResHome{
  width: 95%;
  margin: 20px 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spaced{
  margin-bottom: 10px;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}













@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Footer */

.websiteFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #183042;
  color: white;
  font-size: 12px;
  text-align: center;
  height: 50px;
  padding: 14px 5px;
}

a {
  text-decoration: underline; /* Underline the links */
  font-weight: 500; /* Slightly less bold */
  color: #00008b; /* Dark blue for a bold, clear look */
  transition: color 0.2s ease-in-out; /* Smooth color transition on hover */
}

a:hover {
  color: #000066; /* Even darker blue on hover */
}
